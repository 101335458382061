import React from "react";
import { Link } from "gatsby";
import { BsCheckCircle } from "@react-icons/all-files/bs/BsCheckCircle";
import { IconContext } from "@react-icons/all-files";
import Email from "../components/contact/email";
import Social from "../components/contact/social";
import styles from "../styles/contact.module.scss";
import successStyles from "../styles/contactSuccess.module.scss";


export default function Index() {
  return (
    <div>
      <h1 className={ `header`}>
        Contact
      </h1>
      <p style={{ marginBottom: '36px' }}>
        Please feel free to reach out and send me a message!
      </p>
      <div className={ styles.container }>
        <div className={ styles.left } >
          <div className={ successStyles.success__container }>
            <IconContext.Provider value={{ color: "#50e8b1", size: "44px" }}>
              <BsCheckCircle className={ successStyles.success__icon } />
            </IconContext.Provider>
            <div>
              <h2>Your message was successfully sent!</h2>
              <p>I'll try my best to get back to you as soon as possible. <Link className="link" to="/contact">
                Click here to send another message.
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className={ styles.right }>
          <Email />
          <Social />
        </div>
      </div>
    </div>
  )
};
